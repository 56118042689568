<template>
	<v-main class="content-box d-flex align-center justify-center">
		<v-card variant="flat" class="content-box-card">
			<v-card-item>
				<v-card-title>
					Профиль компании
				</v-card-title>

				<v-card-subtitle>
					Company Profile
				</v-card-subtitle>


			</v-card-item>

			<v-card-text>
				Компания KAMISHIRO TOOLS (DONGGUAN) CO., LTD находится в провинции Гуандун, Китай, и является
				профессиональным производителем деталей для насосов. Мы в основном производим и поставляем
				радиально-поршневые пары, компоненты радиально-поршневых насосов, компоненты насосов для привода
				гидравлических динамометрических ключей, компоненты насосов для привода натяжителей гаек, другие
				компоненты гидравлических насосов и т. д.
			</v-card-text>
			<v-card-text>
				Мы имеем богатый опыт в исследованиях, разработках и производстве гидравлических деталей и продолжаем
				предоставлять продукцию и услуги производителям гидравлического оборудования, рынкам обслуживания
				гидравлического оборудования, пожарным центрам, инженерным группам инфраструктуры, нефтехимическим
				инженерным группам и инженерным группам по установке ветроэнергетических установок.
			</v-card-text>
			<v-card-text>
				Поскольку KAMISHIRO предоставляет продукты и услуги все большему количеству клиентов со всего мира, он
				завоевал поддержку и доверие клиентов и людей из всех слоев общества.
			</v-card-text>
			<v-card style="text-align: center; margin: 0 10%;">
				<v-img v-for="(img, i) in Imgs" :key="i" :src="img.src" cover></v-img>
			</v-card>

		</v-card>
		<v-card class="content-link">
			<v-row>
				<v-col cols="12" md="6">
					<v-card style="background-color: #f4f4f4; min-height: 310px;">
						<template v-slot:title >
							<div style="color: #f4480f">
								продукт
							</div>
							
						</template>
		
						<template v-slot:text>
							Наша основная продукция — гидравлические радиально-поршневые и гидравлические насосы.
							Нажмите на ссылку ниже, чтобы получить больше информации о продукте.
						</template>
		
						<template v-slot:actions>
		
							<v-list style="background-color: #f4f4f4;">
								<v-list-item v-for="(item, i) in productItems" :key="i" :value="item"
									:to="item.link">
									<template v-slot:prepend>
										<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
									</template>
		
									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item>
							</v-list>
						</template>
		
		
					</v-card>
		
					<router-link to="/ProductCatalog" style="text-decoration-line: none;">
						<v-card style="margin-top: 0.5rem; background-color: #f4f4f4; border: 1px solid #f4480f;" color="#f4480f"
							variant="outlined" append-icon="mdi-open-in-new" class="mx-auto" rel="noopener"
							subtitle="каталог продукции"></v-card>
					</router-link>
				</v-col>
		
				<v-col cols="12" md="6">
					<v-card style="background-color: #f4f4f4; min-height: 310px;">
						<template v-slot:title>
							<div style="color: #ff470f">
								Контактная информация
							</div>
							
						</template>
		
						<template v-slot:text>
							Если вас заинтересовала наша продукция, пожалуйста, свяжитесь с нами. Мы предоставим вам
							высококачественную продукцию и отличный сервис.
						</template>
		
						<template v-slot:actions>
		
							<v-list style="background-color: #f4f4f4;">
								<v-list-item v-for="(item, i) in connectItems" :key="i" :value="item"
									color="primary">
									<template v-slot:prepend>
										<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
									</template>
		
									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item>
							</v-list>
						</template>
		
		
					</v-card>
		
					<v-card style="margin-top: 0.5rem;background-color: #f4f4f4; border: 1px solid #f4480f;" variant="outlined" color="#f4480f"
						append-icon="mdi-open-in-new" class="mx-auto" href="mailto:hydraulic@kamishirotools.com"
						rel="noopener" target="_blank" subtitle="отправить электронное письмо"></v-card>
				</v-col>
			</v-row>
		</v-card>

	</v-main>
</template>

<script>
	export default {
		data: () => ({
			Imgs: [{
					src: require('@/assets/Company1.jpg')
				},
				{
					src: require('@/assets/Company2.jpg')
				},
				{
					src: require('@/assets/Company3.jpg')
				},
				{
					src: require('@/assets/Company4.jpg')
				},
				{
					src: require('@/assets/Company5.jpg')
				},
			],
			items: [{
					title: 'Гидравлическая плунжерная пара',
				},
				{
					title: 'Гидравлический насос',
				},
				{
					title: 'Информация о продукте',
				},
			],
			itemCompanyImgs: [{
					src: require('@/assets/P2.jpg'),
				},
				{
					src: require('@/assets/P3.jpg'),
				}
			],
			itemProductImgs: [{
					src: require('@/assets/beng1.jpg'),
				},
				{
					src: require('@/assets/PE-HD.jpg'),
				},
				{
					src: require('@/assets/beng2.jpg'),
				},
				{
					src: require('@/assets/beng3.jpg'),
				},
				{
					src: require('@/assets/MPB-HD.jpg'),
				},
				{
					src: require('@/assets/beng1.jpg'),
				},
				{
					src: require('@/assets/MPE-HD.jpg'),
				},
				{
					src: require('@/assets/beng2.jpg'),
				},
			],
			productItems: [{
					text: 'Гидравлическая плунжерная пара',
					icon: 'mdi-open-in-new',
					link: '/PistonPage'
				},
				{
					text: 'Гидравлический насос',
					icon: 'mdi-open-in-new',
					link: '/PumpPage'
				},
				{
					text: 'другой',
					icon: 'mdi-open-in-new',
					link: '/ProductCatalog'
				},
			],
			connectItems: [{
					text: 'hydraulic@kamishirotools.com',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WhatsApp: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WeChat: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
			],
			icons: [
				'mdi-facebook',
				'mdi-twitter',
				'mdi-linkedin',
				'mdi-instagram',
			],
		}),

		watch: {
			group() {
				this.drawer = false
			},
		},
	}
</script>

<style>
</style>
