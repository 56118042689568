<template>
	<v-card>
		<v-layout>
			<template v-if="$vuetify.display.mdAndUp">
				<v-navigation-drawer>
					<v-list>
						<v-list-item></v-list-item>
					</v-list>
				</v-navigation-drawer>
				<v-navigation-drawer location="right">
					<v-list>
						<v-list-item></v-list-item>
					</v-list>
				</v-navigation-drawer>
			</template>
			<v-app-bar class="top-bar" color="" prominent @click.stop="drawer = !drawer">
				<v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

				<v-toolbar-title>Kamishiro Hydraulic</v-toolbar-title>

				<v-spacer></v-spacer>

				<template v-if="$vuetify.display.mdAndUp">
					<v-btn icon="mdi-magnify" variant="text"></v-btn>

					<v-btn icon="mdi-filter" variant="text"></v-btn>
				</template>

				<v-btn icon="mdi-dots-vertical" variant="text"></v-btn>
			</v-app-bar>


			<router-view></router-view>
			<v-navigation-drawer v-model="drawer" location="top" temporary class="navig-drawer">
				<v-list>

					<v-list-item v-for="(item, i) in items" :key="i" :value="item" color="primary"
						@click="topBarClick(item)">
						<v-list-item-title v-text="item.title"></v-list-item-title>
					</v-list-item>

				</v-list>
			</v-navigation-drawer>
			
		</v-layout>
		
	</v-card>
	<v-footer class="d-flex flex-column">
		<div class="bg-teal d-flex w-100 align-center px-4">
			<template v-if="$vuetify.display.mdAndUp">
				<strong>Get connected with us on social networks!</strong>
			</template>
			<v-spacer></v-spacer>

			<v-btn v-for="icon in icons" :key="icon" :icon="icon" class="mx-4" size="small" variant="plain">
			</v-btn>
		</div>

		<div class="px-4 py-2 bg-black text-center w-100">
			{{ new Date().getFullYear() }} — <strong>Kamishiro Hydraulic</strong>
		</div>
	</v-footer>
</template>

<script>
	export default {
		data: () => ({
			drawer: false,
			group: null,
			items: [{
					title: 'Домашний',
					link: '/',
				},
				{
					title: 'каталог продукции',
					link: '/ProductCatalog',
				},
				{
					title: 'Гидравлическая плунжерная пара',
					link: '/PistonPage',
				},
				{
					title: 'Гидравлический насос',
					link: '/PumpPage',
				}
			],
			itemCompanyImgs: [{
					src: require('@/assets/1.png'),
				},
				{
					src: require('@/assets/2.png'),
				},
				{
					src: require('@/assets/3.png'),
				},
			],
			productItems: [{
					text: '液压柱塞副',
					icon: 'mdi-open-in-new'
				},
				{
					text: '液压泵',
					icon: 'mdi-open-in-new'
				},
				{
					text: '其他',
					icon: 'mdi-open-in-new'
				},
			],
			connectItems: [{
					text: '邮箱',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WhatsApp',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WeChat',
					icon: 'mdi-open-in-new'
				},
			],
			icons: [
				'mdi-facebook',
				'mdi-youtube',
				'mdi-instagram',
			],
		}),

		watch: {
			group() {
				this.drawer = false
			},
		},
		methods: {
			topBarClick(item) {
				// 当列表项被点击时执行的代码
				console.log('选中的列表项：', item.title);
				this.$router.push(item.link);
			}
		}
	}
</script>

<style>
	.v-toolbar-title__placeholder {
		overflow: inherit;
	}
	.v-navigation-drawer__content{
		color: #f4480f !important;
	}
	.content-box {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		min-height: 600px;

		.content-box-card {
			flex: 1;
			min-width: 400px;

			.content-carousel {
				height: 600px !important;

				img {
					width: 100%;
				}
			}

		}

	}

	.product-box-card {
		display: flex;
		height: 320px;
	}

	.content-link {
		margin-top: 2rem;
		padding: 1rem;
	}

	@media screen < 500px {
		.content-box {
			display: block;
		}

		.content-box-card {

			min-width: 100%;
		}
	}
</style>
