<template>
	<v-main class="content-box d-flex align-center justify-center">
		<v-card width="100%">
			<v-row width="100%">
				<v-col cols="12" md="5">
					<v-card class="mx-auto">
						<router-link to="/PistonPage" style="text-decoration-line: none;">
							<v-img :src="require('@/assets/productList-Plunger.jpg')" cover></v-img>
						</router-link>

						<v-card-title>
							<div style="color: #f4480f">
								Гидравлическая плунжерная пара
							</div>
						</v-card-title>

						<v-card-subtitle>
							Нажмите на изображение, чтобы узнать больше
						</v-card-subtitle>

						<v-card-actions @click="show1 = !show1">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show1">
								<v-divider></v-divider>

								<v-card-text>
									Ключевые слова: #гидравлика #гидравлическая плунжерная пара #клапан
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="12" md="1"></v-col>
				<v-col cols="12" md="5">
					<v-card class="mx-auto">
						<router-link to="/PumpPage" style="text-decoration-line: none;">
							<v-img :src="require('@/assets/AllPumps.jpg')" cover></v-img>
						</router-link>
						<v-card-title>
							<div style="color: #f4480f">
								Гидравлический насос
							</div>
						</v-card-title>

						<v-card-subtitle>
							Нажмите на изображение, чтобы узнать больше
						</v-card-subtitle>

						<v-card-actions @click="show = !show">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show">
								<v-divider></v-divider>

								<v-card-text>
									Ключевые слова: #гидравлический #гидравлическийнасос #насосвысокого давления
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
		<v-card class="content-link">
			<v-row>
				<v-col cols="12" md="6">
					<v-card style="background-color: #f4f4f4;">
						<template v-slot:title>
							<div style="color: #f4480f">
								продукт
							</div>

						</template>

						<template v-slot:text>
							Наша основная продукция — гидравлические радиально-поршневые и гидравлические насосы.
							Нажмите на ссылку ниже, чтобы получить больше информации о продукте.
						</template>

						<template v-slot:actions>

							<v-list style="background-color: #f4f4f4;">
								<v-list-item v-for="(item, i) in productItems" :key="i" :value="item" color="primary"
									:to="item.link" link>
									<template v-slot:prepend>
										<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
									</template>

									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item>
							</v-list>
						</template>


					</v-card>

					<router-link to="/ProductCatalog" style="text-decoration-line: none;">
						<v-card style="margin-top: 0.5rem; background-color: #f4f4f4;border: 1px solid #f4480f;"
							color="#f4480f" variant="outlined" append-icon="mdi-open-in-new" class="mx-auto"
							rel="noopener" subtitle="каталог продукции">
						</v-card>
					</router-link>
				</v-col>

				<v-col cols="12" md="6">
					<v-card style="background-color: #f4f4f4;">
						<template v-slot:title>
							<div style="color: #f4480f">
								Контактная информация
							</div>

						</template>

						<template v-slot:text>
							Если вас заинтересовала наша продукция, пожалуйста, свяжитесь с нами. Мы предоставим вам
							высококачественную продукцию и отличный сервис.
						</template>

						<template v-slot:actions>

							<v-list style="background-color: #f4f4f4;">
								<v-list-item v-for="(item, i) in connectItems" :key="i" :value="item" color="primary"
									:to="item.link">
									<template v-slot:prepend>
										<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
									</template>

									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item>
							</v-list>
						</template>


					</v-card>

					<v-card style="margin-top: 0.5rem;background-color: #f4f4f4; border: 1px solid #f4480f;"
						variant="outlined" color="#f4480f" append-icon="mdi-open-in-new" class="mx-auto"
						href="mailto:hydraulic@kamishirotools.com" rel="noopener" target="_blank"
						subtitle="отправить электронное письмо"></v-card>
				</v-col>
			</v-row>
		</v-card>

	</v-main>
</template>

<script>
	export default {
		data: () => ({
			show: false,
			show1: false,
			productItems: [{
					text: 'Гидравлическая плунжерная пара',
					icon: 'mdi-open-in-new',
					link: '/PistonPage'
				},
				{
					text: 'Гидравлический насос',
					icon: 'mdi-open-in-new',
					link: '/PumpPage'
				},
				{
					text: 'другой',
					icon: 'mdi-open-in-new',
					link: ''
				},
			],
			connectItems: [{
					text: 'hydraulic@kamishirotools.com',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WhatsApp: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WeChat: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
			],
		}),
	}
</script>

<style>
</style>
