<template>
	<v-card>
		<v-main class="content-box d-flex align-center justify-center">



			<v-card variant="flat" class="content-box-card">
				<v-carousel hide-delimiters cycle class="content-carousel" >
					<v-carousel-item v-for="(item,i) in itemCompanyImgs" :key="i" :src="item.src"> 
					</v-carousel-item>
				</v-carousel>
			</v-card>
			<v-card variant="flat" class="content-box-card">
				<v-card-item>
					<v-card-title style="color: #f4480f">
						Профиль компании
					</v-card-title>
				</v-card-item>

				<v-card-text>
					WE——KAMISHIRO TOOLS(DONGGUAN)CO.,LTD, расположенная в провинции Гуандун, Китай, является
					профессиональным производителем принадлежностей для радиально-поршневых насосов. В основном мы
					производим плунжерные клапаны, принадлежности для радиально-поршневых плунжерных насосов,
					принадлежности для насосов для привода гидравлических динамометрических ключей, принадлежности для
					насосов для привода гидравлических натяжителей болтов и другие принадлежности для гидравлических
					насосов.
					У нас богатый опыт в производстве гидравлических деталей, и мы продолжаем поставлять детали для
					насосов и принадлежности для насосов для насосных заводов, рынков обслуживания гидравлического
					оборудования, пожарных центров, дорожно-спасательных центров, групп по проектированию
					инфраструктуры, групп по проектированию нефтехимической промышленности, центров ветрогенераторов.
				</v-card-text>
				<router-link to="/company" style="text-decoration-line: none;">
					<v-card style="margin-bottom: 1rem" variant="outlined" color="#000" append-icon="mdi-open-in-new"
						class="mx-auto" rel="noopener" subtitle="каталог продукции"></v-card>
				</router-link>

				<v-card class="product-box-card">
					<v-carousel hide-delimiters cycle>
						<v-carousel-item v-for="(item,i) in itemProductImgs" :key="i" :src="item.src">
						</v-carousel-item>
					</v-carousel>
				</v-card>
			</v-card>
			<v-card class="content-link">
				<v-row>
					<v-col cols="12" md="6">
						<v-card style="background-color: #f4f4f4; min-height: 310px;">
							<template v-slot:title >
								<div style="color: #f4480f">
									продукт
								</div>
								
							</template>

							<template v-slot:text>
								Наша основная продукция — гидравлические радиально-поршневые и гидравлические насосы.
								Нажмите на ссылку ниже, чтобы получить больше информации о продукте.
							</template>

							<template v-slot:actions>

								<v-list style="background-color: #f4f4f4;">
									<v-list-item v-for="(item, i) in productItems" :key="i" :value="item"
										:to="item.link">
										<template v-slot:prepend>
											<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
										</template>

										<v-list-item-title v-text="item.text"></v-list-item-title>
									</v-list-item>
								</v-list>
							</template>


						</v-card>

						<router-link to="/ProductCatalog" style="text-decoration-line: none;">
							<v-card style="margin-top: 0.5rem; background-color: #f4f4f4; border: 1px solid #f4480f;" color="#f4480f"
								variant="outlined" append-icon="mdi-open-in-new" class="mx-auto" rel="noopener"
								subtitle="каталог продукции"></v-card>
						</router-link>
					</v-col>

					<v-col cols="12" md="6">
						<v-card style="background-color: #f4f4f4; min-height: 310px;">
							<template v-slot:title>
								<div style="color: #ff470f">
									Контактная информация
								</div>
								
							</template>

							<template v-slot:text>
								Если вас заинтересовала наша продукция, пожалуйста, свяжитесь с нами. Мы предоставим вам
								высококачественную продукцию и отличный сервис.
							</template>

							<template v-slot:actions>

								<v-list style="background-color: #f4f4f4;">
									<v-list-item v-for="(item, i) in connectItems" :key="i" :value="item"
										color="primary">
										<template v-slot:prepend>
											<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
										</template>

										<v-list-item-title v-text="item.text"></v-list-item-title>
									</v-list-item>
								</v-list>
							</template>


						</v-card>

						<v-card style="margin-top: 0.5rem;background-color: #f4f4f4; border: 1px solid #f4480f;" variant="outlined" color="#f4480f"
							append-icon="mdi-open-in-new" class="mx-auto" href="mailto:hydraulic@kamishirotools.com"
							rel="noopener" target="_blank" subtitle="отправить электронное письмо"></v-card>
					</v-col>
				</v-row>
			</v-card>

		</v-main>
	</v-card>

</template>

<script>
	export default {
		data: () => ({
			drawer: false,
			group: null,
			items: [{
					title: 'Гидравлическая плунжерная пара',
				},
				{
					title: 'Гидравлический насос',
				},
				{
					title: 'Информация о продукте',
				},
			],
			itemCompanyImgs: [{
					src: require('@/assets/P2.jpg'),
				},
				{
					src: require('@/assets/P3.jpg'),
				}
			],
			itemProductImgs: [{
					src: require('@/assets/beng1.jpg'),
				},
				{
					src: require('@/assets/PE-HD.jpg'),
				},
				{
					src: require('@/assets/beng2.jpg'),
				},
				{
					src: require('@/assets/beng3.jpg'),
				},
				{
					src: require('@/assets/MPB-HD.jpg'),
				},
				{
					src: require('@/assets/beng1.jpg'),
				},
				{
					src: require('@/assets/MPE-HD.jpg'),
				},
				{
					src: require('@/assets/beng2.jpg'),
				},
			],
			productItems: [{
					text: 'Гидравлическая плунжерная пара',
					icon: 'mdi-open-in-new',
					link: '/PistonPage'
				},
				{
					text: 'Гидравлический насос',
					icon: 'mdi-open-in-new',
					link: '/PumpPage'
				},
				{
					text: 'другой',
					icon: 'mdi-open-in-new',
					link: '/ProductCatalog'
				},
			],
			connectItems: [{
					text: 'hydraulic@kamishirotools.com',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WhatsApp: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WeChat: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
			],
			icons: [
				'mdi-facebook',
				'mdi-twitter',
				'mdi-linkedin',
				'mdi-instagram',
			],
		}),

		watch: {
			group() {
				this.drawer = false
			},
		},
	}
</script>

<style>
	.content-box {
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		min-height: 400px;

		.content-box-card {
			flex: 1;
			min-width: 400px;

			.content-carousel {
				height: 400px !important;

				img {
					height: 100%;
				}
			}

		}

	}

	.product-box-card {
		display: flex;
		height: 320px;

		.v-carousel {
			height: 320px !important;
		}
	}

	.content-link {
		margin-top: 2rem;
		padding: 1rem;
	}

	@media screen < 500px {
		.content-box {
			display: block;
		}

		.content-box-card {

			min-width: 100%;
		}
	}
</style>
