<template>
	<v-main class="content-box d-flex align-center justify-center">
		<v-card variant="flat" class="content-box-card">
			<v-img :aspect-ratio="1" class="bg-white" :src="require('@/assets/productList-Plunger.jpg')" width="100%"
				cover></v-img>
		</v-card>
		<v-card variant="flat" class="content-box-card">
			<v-card-item>
				<v-card-title>
					<div style="color: #f4480f">
						Гидравлическая плунжерная пара
					</div>
					
				</v-card-title>
			</v-card-item>

			<v-card-text>
				Плунжерная пара Kamishiro представляет собой высококачественный гидравлический компонент производства
				Kamishiro Kamishiro, обладающий следующими характеристиками:
			</v-card-text>
			<v-card-text>
				- Принцип работы: плунжерная пара использует плунжер в качестве принципа работы, а
				возвратно-поступательное движение плунжера обеспечивает всасывание и слив жидкости, образуя непрерывную
				подачу масла.

				- Характеристики продукта: небольшой размер, длительный срок службы, отсутствие утечек, интеграция,
				хорошая надежность, компактная конструкция и в основном стальной корпус клапана.

				- Широкое применение: применимо к различным гидравлическим системам и оборудованию, таким как
				гидравлические насосы, гидравлические масляные насосы и т. д. ‌
				-‌Различные серии‌:‌ Включая серию PE, серию MPE и другие модели, такие как MPE4, MPE5, MPE6, MPE7,
				MPE8, MPE9, MPB11, MPB12, PE6, PE7, PE8, PE10, PE12, PE13, PE14, PE15. , ПЭ16 и т. д.‌
			</v-card-text>
			<v-card-text>
				Обратите внимание, что при выборе и использовании плунжерной пары Shendai выбор должен основываться на
				конкретных требованиях применения и параметрах оборудования, а также следует соблюдать соответствующие
				спецификации по установке и техническому обслуживанию.
			</v-card-text>
			<v-card-text>
				<v-btn prepend-icon="mdi-download" style="font-size: 0.9rem;color:#f4480f;" id="download" @click="clickDownload">
					Download Product Catalog(PDF)
				</v-btn>
			</v-card-text>
		</v-card>
		<v-card width="100%" style="margin-top: 1rem;">
			<v-card-item>
				<v-card-title>
					<div style="color: #f4480f">
						Плунжерный узел серии MPB
					</div>
					
				</v-card-title>
				<v-card-subtitle>
					Plunger Assembly MPB Series
				</v-card-subtitle>
			</v-card-item>
			<v-row width="100%">
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="MPB[0].src" cover></v-img>

						<v-card-title>
							MPB HC
						</v-card-title>

						<v-card-subtitle>
							{{MPB[0].description}}
						</v-card-subtitle>

						<v-card-actions @click="show1 = !show1">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show1 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show1">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="MPB[1].src" cover></v-img>

						<v-card-title>
							MPB
						</v-card-title>

						<v-card-subtitle>
							{{MPB[1].description}}
						</v-card-subtitle>

						<v-card-actions @click="show2 = !show2">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show2 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show2">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="MPB[2].src" cover></v-img>

						<v-card-title>
							MPB HKL
						</v-card-title>

						<v-card-subtitle>
							{{MPB[2].description}}
						</v-card-subtitle>

						<v-card-actions @click="show3 = !show3">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show3 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show3">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
		<v-card width="100%" style="margin-top: 1rem;">
			<v-card-item>
				<v-card-title>
					<div style="color: #f4480f">
						Плунжерный узел серии MPE
					</div>
					
				</v-card-title>
				<v-card-subtitle>
					Plunger Assembly MPE Series
				</v-card-subtitle>
			</v-card-item>
			<v-row width="100%">
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="MPE[0].src" cover></v-img>

						<v-card-title>
							MPE HC
						</v-card-title>

						<v-card-subtitle>
							{{MPE[0].description}}
						</v-card-subtitle>

						<v-card-actions @click="show1 = !show1">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show1 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show1">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="MPE[1].src" cover></v-img>

						<v-card-title>
							MPE
						</v-card-title>

						<v-card-subtitle>
							{{MPE[1].description}}
						</v-card-subtitle>

						<v-card-actions @click="show2 = !show2">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show2 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show2">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="MPE[2].src" cover></v-img>

						<v-card-title>
							MPE HKL
						</v-card-title>

						<v-card-subtitle>
							{{MPE[2].description}}
						</v-card-subtitle>

						<v-card-actions @click="show3 = !show3">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show3 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show3">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
		<v-card width="100%" style="margin-top: 1rem;">
			<v-card-item>
				<v-card-title>
					<div style="color: #f4480f">
						Плунжерный узел серии PE
					</div>
					
				</v-card-title>
				<v-card-subtitle>
					Plunger Assembly PE Series
				</v-card-subtitle>
			</v-card-item>
			<v-row width="100%">
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="PE[0].src" cover></v-img>

						<v-card-title>
							PE HC
						</v-card-title>

						<v-card-subtitle>
							{{PE[0].description}}
						</v-card-subtitle>

						<v-card-actions @click="show1 = !show1">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show1 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show1">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="PE[1].src" cover></v-img>

						<v-card-title>
							PE
						</v-card-title>

						<v-card-subtitle>
							{{PE[1].description}}
						</v-card-subtitle>

						<v-card-actions @click="show2 = !show2">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show2 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show2">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="6" md="4" xs="3" sm="3">
					<v-card class="mx-auto">
						<v-img :src="PE[2].src" cover></v-img>

						<v-card-title>
							PE HKL
						</v-card-title>

						<v-card-subtitle>
							{{PE[2].description}}
						</v-card-subtitle>

						<v-card-actions @click="show3 = !show3">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show3 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show3">
								<v-divider></v-divider>

								<v-card-text>
									{{MPB[0].text}}
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
		<v-card class="content-link">
			<v-row>
				<v-col cols="12" md="6">
					<v-card style="background-color: #f4f4f4;">
						<template v-slot:title>
							<div style="color: #f4480f">
								продукт
							</div>
							
						</template>

						<template v-slot:text>
							Наша основная продукция — гидравлические радиально-поршневые и гидравлические насосы.
							Нажмите на ссылку ниже, чтобы получить больше информации о продукте.
						</template>

						<template v-slot:actions>

							<v-list style="background-color: #f4f4f4;">
								<v-list-item v-for="(item, i) in productItems" :key="i" :value="item" color="primary"
									:to="item.link" link>
									<template v-slot:prepend>
										<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
									</template>

									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item>
							</v-list>
						</template>


					</v-card>

					<router-link to="/ProductCatalog" style="text-decoration-line: none;">
						<v-card style="margin-top: 0.5rem; background-color: #f4f4f4;border: 1px solid #f4480f;" color="#f4480f" variant="outlined"
							append-icon="mdi-open-in-new" class="mx-auto" rel="noopener" subtitle="каталог продукции">
						</v-card>
					</router-link>
				</v-col>

				<v-col cols="12" md="6">
					<v-card style="background-color: #f4f4f4;">
						<template v-slot:title>
							<div style="color: #f4480f">
								Контактная информация
							</div>
							
						</template>

						<template v-slot:text>
							Если вас заинтересовала наша продукция, пожалуйста, свяжитесь с нами. Мы предоставим вам
							высококачественную продукцию и отличный сервис.
						</template>

						<template v-slot:actions>

							<v-list style="background-color: #f4f4f4;">
								<v-list-item v-for="(item, i) in connectItems" :key="i" :value="item" color="primary"
									:to="item.link">
									<template v-slot:prepend>
										<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
									</template>

									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item>
							</v-list>
						</template>


					</v-card>

					<v-card style="margin-top: 0.5rem;background-color: #f4f4f4; border: 1px solid #f4480f;" variant="outlined" color="#f4480f"
						append-icon="mdi-open-in-new" class="mx-auto" href="mailto:hydraulic@kamishirotools.com"
						rel="noopener" target="_blank" subtitle="отправить электронное письмо"></v-card>
				</v-col>
			</v-row>
		</v-card>

	</v-main>
</template>

<script>
	export default {
		data: () => ({
			show1: false,
			show2: false,
			show3: false,
			show4: false,
			MPB: [{
				title: 'MPB1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/MPB1.jpg'),
				description: 'без фильтрующей сетки',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}, {
				title: 'MPB1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/MPB2.jpg'),
				description: 'с фильтрующей сеткой',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}, {
				title: 'MPB1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/MPB-3.jpg'),
				description: 'С соломой',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}],
			MPE: [{
				title: 'MPE1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/MPE-1.jpg'),
				description: 'без фильтрующей сетки',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}, {
				title: 'MPE1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/MPE-2.jpg'),
				description: 'с фильтрующей сеткой',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}, {
				title: 'MPE1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/MPE-3.jpg'),
				description: 'С соломой',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}],
			PE: [{
				title: 'MPE1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/PE-1.jpg'),
				description: 'без фильтрующей сетки',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}, {
				title: 'MPE1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/PE-2.jpg'),
				description: 'с фильтрующей сеткой',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}, {
				title: 'MPE1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/PE-3.jpg'),
				description: 'С соломой',
				text: "Ключевые слова: #гидравлическая поршневая пара #радиально-поршневая пара #гидравлическая машина #детали гидравлического насоса"
			}],
			productItems: [{
					text: 'Гидравлическая плунжерная пара',
					icon: 'mdi-open-in-new',
					link: ''
				},
				{
					text: 'Гидравлический насос',
					icon: 'mdi-open-in-new',
					link: '/PumpPage'
				},
				{
					text: 'другой',
					icon: 'mdi-open-in-new',
					link: '/ProductCatalog'
				},
			],
			connectItems: [{
					text: 'hydraulic@kamishirotools.com',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WhatsApp: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WeChat: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
			],
		}),
		methods: {
			clickDownload() {
				const downloadButton = document.getElementById('download');
				const fileURL = "/Kamishiro Hydraulic Product Catalog.pdf"; // 用实际文件路径替换
				const fileName = "Kamishiro Hydraulic Product Catalog"; // 用下载后的文件名替换
				downloadButton.addEventListener('click', function() {
					const downloadLink = document.createElement('a');
					downloadLink.href = fileURL;
					downloadLink.download = fileName;
					downloadLink.style.display = 'none'; // 隐藏链接
					document.body.appendChild(downloadLink);

					downloadLink.click();

					document.body.removeChild(downloadLink);
				})
			}
		},
		mounted() {
			// 组件已经挂载到DOM
			// 可以访问组件的DOM元素，执行DOM初始化工作
			this.downloadButton = document.getElementById('download');
			if (this.downloadButton) {
				this.downloadButton.addEventListener('click', this.clickDownload);
			} else {
				console.error('Element with id "download" not found');
			}
		},
		beforeUnmount() {
			// 移除事件监听器以防止内存泄漏
			if (this.downloadButton) {
				this.downloadButton.removeEventListener('click', this.clickDownload);
			}
		},
	}
</script>

<style>
</style>
