// src/router/index.js
import {
	createRouter,
	createWebHistory
} from 'vue-router'
import HomePage from '../HomePage.vue'
import CompanyPage from '../CompanyPage.vue'
import ProductCatalogPage from '../ProductCatalogPage.vue'
import PistonPage from '../PistonPage.vue'
import PumpPage from '../PumpPage.vue'

const routes = [{
		path: '/',
		name: 'Home',
		component: HomePage
	},
	{
		path: '/company',
		name: 'Company',
		component: CompanyPage
	},
	{
		path: '/ProductCatalog',
		name: 'ProductCatalog',
		component: ProductCatalogPage
	},
	{
		path: '/PistonPage',
		name: 'PistonPage',
		component: PistonPage
	},
	{
		path: '/PumpPage',
		name: 'PumpPage',
		component: PumpPage
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		// 滚动到顶部
		return {
			top: 0
		}
	}
})

export default router
