<template>
	<v-main class="content-box d-flex align-center justify-center">
		<v-card variant="flat" class="content-box-card">
			<v-img :aspect-ratio="1" class="bg-white" :src="require('@/assets/AllPumps.jpg')" width="100%" cover>
			</v-img>
		</v-card>
		<v-card variant="flat" class="content-box-card">
			<v-card-item>
				<v-card-title>
					<div style="color: #f4480f">
						Гидравлический насос
					</div>
					
				</v-card-title>
			</v-card-item>

			<v-card-text>
				Радиально-поршневой насос R&RG (R&RG) состоит из нескольких поршневых цилиндров, которые расположены в
				звездообразной схеме. Он обеспечивает большой выходной поток. Он соединен с двигателем с помощью фланца
				и муфты, приводится в действие двигателем.
			</v-card-text>
			<v-card-text>
				<v-btn prepend-icon="mdi-download" style="font-size: 0.9rem;color:#f4480f;" id="download" @click="clickDownload">
					Download Product Catalog(PDF)
				</v-btn>
			</v-card-text>
		</v-card>
		<v-card width="100%" style="margin-top: 1rem;">
			<v-card-item>
				<v-card-title>
					<div style="color: #f4480f">
						Гидравлический насос
					</div>
					
				</v-card-title>
				<v-card-subtitle>
					Hydraulic Pump Series
				</v-card-subtitle>
			</v-card-item>
			<v-row width="100%">
				<v-col cols="12" md="4">
					<v-card class="mx-auto">
						<v-img :src="MPB[0].src" cover></v-img>

						<v-card-title>
							Radial Piston Pump Assembly(RB)
						</v-card-title>

						<v-card-subtitle>
							For Hydraulic Torque Wrench
						</v-card-subtitle>

						<v-card-actions @click="show1 = !show1">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show1 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show1">
								<v-divider></v-divider>

								<v-card-text>
									Радиально-поршневой насос в сборе для гидравлического динамометрического ключа (RB)
									представляет собой трехступенчатую насосную группу, обычно состоящую из 4 плунжеров
									MPE и 4 плунжеров MPB. Эти плунжеры независимы друг от друга. Он оснащен
									предохранительными клапанами высокой и низкой нагрузки и фильтрующим кожухом. Он
									соединен с двигателем через фланец, приводимый в действие двигателем. Он
									поддерживает большой выходной поток. Его режим установки - погружение в масло, что
									часто используется для привода гидравлического динамометрического ключа.
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="12" md="4">
					<v-card class="mx-auto">
						<v-img :src="MPB[1].src" cover></v-img>

						<v-card-title>
							Radial Piston Pump Assembly(RL)
						</v-card-title>

						<v-card-subtitle>
							For Hydraulic Bolt Tensioner
						</v-card-subtitle>

						<v-card-actions @click="show2 = !show2">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show2 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show2">
								<v-divider></v-divider>

								<v-card-text>
									Радиально-поршневой насос в сборе для гидравлического натяжителя болтов (RL)
									представляет собой двухступенчатую насосную группу, обычно состоящую из 2 плунжеров
									MPE, 2 плунжеров MPB и 2 плунжеров HPE. Он оснащен предохранительными клапанами
									высокой и низкой нагрузки и фильтрующим кожухом. Он соединен с двигателем через
									фланец, приводимый в действие двигателем. Его режим установки - погружение в масло,
									что часто используется для привода гидравлических натяжителей болтов.
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
				<v-col cols="12" md="4">
					<v-card class="mx-auto">
						<v-img :src="MPB[2].src" cover></v-img>

						<v-card-title>
							R&RG Radial Piston Pump Assembly
						</v-card-title>

						<v-card-subtitle>
							R&RG
						</v-card-subtitle>

						<v-card-actions @click="show3 = !show3">
							<v-btn color="orange-lighten-2" text="Explore"></v-btn>

							<v-spacer></v-spacer>

							<v-btn :icon="show3 ? 'mdi-chevron-up' : 'mdi-chevron-down'"></v-btn>
						</v-card-actions>

						<v-expand-transition>
							<div v-show="show3">
								<v-divider></v-divider>

								<v-card-text>
									Радиально-поршневой насос R&RG (R&RG) состоит из нескольких поршневых цилиндров,
									которые расположены в звездообразной схеме. Он обеспечивает большой выходной поток.
									Он соединен с двигателем с помощью фланца и муфты, приводится в действие двигателем.
								</v-card-text>
							</div>
						</v-expand-transition>
					</v-card>
				</v-col>
			</v-row>
		</v-card>
		<v-card class="content-link">
			<v-row>
				<v-col cols="12" md="6">
					<v-card style="background-color: #f4f4f4;">
						<template v-slot:title>
							<div style="color: #f4480f">
								продукт
							</div>

						</template>

						<template v-slot:text>
							Наша основная продукция — гидравлические радиально-поршневые и гидравлические насосы.
							Нажмите на ссылку ниже, чтобы получить больше информации о продукте.
						</template>

						<template v-slot:actions>

							<v-list style="background-color: #f4f4f4;">
								<v-list-item v-for="(item, i) in productItems" :key="i" :value="item" :to="item.link"
									link>
									<template v-slot:prepend>
										<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
									</template>

									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item>
							</v-list>
						</template>


					</v-card>

					<router-link to="/ProductCatalog" style="text-decoration-line: none;">
						<v-card style="margin-top: 0.5rem; background-color: #f4f4f4;border: 1px solid #f4480f;" color="#f4480f" variant="outlined"
							append-icon="mdi-open-in-new" class="mx-auto" rel="noopener" subtitle="каталог продукции">
						</v-card>
					</router-link>
				</v-col>

				<v-col cols="12" md="6">
					<v-card style="background-color: #f4f4f4;">
						<template v-slot:title>
							<div style="color: #f4480f">
								Контактная информация
							</div>
							
						</template>

						<template v-slot:text>
							Если вас заинтересовала наша продукция, пожалуйста, свяжитесь с нами. Мы предоставим вам
							высококачественную продукцию и отличный сервис.
						</template>

						<template v-slot:actions>

							<v-list style="background-color: #f4f4f4;">
								<v-list-item v-for="(item, i) in connectItems" :key="i" :value="item" color="primary">
									<template v-slot:prepend>
										<v-icon :icon="item.icon" style="color: #f4480f"></v-icon>
									</template>

									<v-list-item-title v-text="item.text"></v-list-item-title>
								</v-list-item>
							</v-list>
						</template>


					</v-card>

					<v-card style="margin-top: 0.5rem;background-color: #f4f4f4; border: 1px solid #f4480f;" variant="outlined" color="#f4480f"
						append-icon="mdi-open-in-new" class="mx-auto" href="mailto:hydraulic@kamishirotools.com"
						rel="noopener" target="_blank" subtitle="отправить электронное письмо"></v-card>
				</v-col>
			</v-row>
		</v-card>

	</v-main>
</template>

<script>
	export default {
		data: () => ({
			show1: true,
			show2: true,
			show3: true,
			show4: true,
			MPB: [{
				title: 'MPB1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/RB.jpg'),
				text: "I'm a thing.But,like most politicians,he promised more than he could deliver."
			}, {
				title: 'MPB1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/RL.jpg'),
				text: "I'm a thing.But,like most politicians,he promised more than he could deliver."
			}, {
				title: 'MPB1',
				subtitle: '1,000 miles of wonder',
				src: require('@/assets/RG.jpg'),
				text: "I'm a thing.But,like most politicians,he promised more than he could deliver."
			}],
			productItems: [{
					text: 'Гидравлическая плунжерная пара',
					icon: 'mdi-open-in-new',
					link: '/PistonPage'
				},
				{
					text: 'Гидравлический насос',
					icon: 'mdi-open-in-new',
					link: ''
				},
				{
					text: 'другой',
					icon: 'mdi-open-in-new',
					link: '/ProductCatalog'
				},
			],
			connectItems: [{
					text: 'hydraulic@kamishirotools.com',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WhatsApp: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
				{
					text: 'WeChat: +86 15107730284',
					icon: 'mdi-open-in-new'
				},
			],
		}),
		methods: {
			clickDownload() {
				const downloadButton = document.getElementById('download');
				const fileURL = "/Kamishiro Hydraulic Product Catalog.pdf"; // 用实际文件路径替换
				const fileName = "Kamishiro Hydraulic Product Catalog"; // 用下载后的文件名替换
				downloadButton.addEventListener('click', function() {
					const downloadLink = document.createElement('a');
					downloadLink.href = fileURL;
					downloadLink.download = fileName;
					downloadLink.style.display = 'none'; // 隐藏链接
					document.body.appendChild(downloadLink);

					downloadLink.click();

					document.body.removeChild(downloadLink);
				})
			}
		},
		mounted() {
			// 组件已经挂载到DOM
			// 可以访问组件的DOM元素，执行DOM初始化工作
			this.downloadButton = document.getElementById('download');
			if (this.downloadButton) {
				this.downloadButton.addEventListener('click', this.clickDownload);
			} else {
				console.error('Element with id "download" not found');
			}
		},
		beforeUnmount() {
			// 移除事件监听器以防止内存泄漏
			if (this.downloadButton) {
				this.downloadButton.removeEventListener('click', this.clickDownload);
			}
		},
	}
</script>

<style>
</style>
